:root {
    // ========== Colour ==========
    // Text
    --clr_text_primary: #1a1a1a;
    --clr_text_secondary: #ffffff;
    --clr_text_info: #6378ff;
    --clr_text_danger: #d32f2f;
    --clr_text_success: #48c55e;
    --clr_text_neutral: #333333;
    --clr_text_neutral2: #818181;
    --clr_text_neutral4: #5a5a5a;
    --clr_text_neutral5: #898989;
    --clr_text_neutrul8: #79747e;
    --clr_text_neutrul9: #c9c5ca;

    // Background
    --clr_background_primary: #ffffff;
    --clr_bg_error_light: #fffbf9;
    --clr_background_light: #edf0ff;
    --clr_background_light_2: #f6f7ff;
    --clr_background_light_4: #d8dcf7;
    --clr_background_light_6: #eff1ff;
    --clr_background_info: #6378ff;
    --clr_background_info_dark: #3951e8;
    --clr_background_info_primary_alpha_50: hsla(231.9, 100%, 69.4%, 0.5);
    --clr_background_primary_alpha_25: hsla(0, 0%, 100%, 0.219);
    --clr_background_info_primary_hover: #7a8cff;

    --clr_bg_classPoint_02: #dbe0ff;

    // Border & Outline
    --clr_border_primary: #6378ff;
    --clr_border_classPoint_02: #9daafb;
    --clr_border_black_025: rgba(36, 28, 21, 0.25);
    --clr_border_neutral_5: #e2e2e2;

    // Gradient
    --custom_btn_grad_bg_1: linear-gradient(75.66deg, #6378ff 17.84%, #8652f4 69.88%);
    --custom_btn_grad_bg_2: linear-gradient(75.66deg, rgb(68, 88, 221) f 17.84%, #6e37e5 69.88%);

    // Transition
    --transition: all 0.2s ease-in-out;
    --transition_Duration: 222ms;

    // ========== Font Family ==========
    --font_primary: 'Poppins', sans-serif;
    --font_secondary: 'DM Sans', sans-serif;

    // ========== Font Weight ==========
    --fw_regular: 400;
    --fw_medium: 500;
    --fw_semiBold: 600;
    --fw_bold: 700;
}
